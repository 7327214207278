.advantages-section-container {
  position: relative;
  background-color: white;
  box-shadow: 0 0 0 100vmax white;
  clip-path: inset(0 -100vmax);
}
.container-of-advantage {
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  place-items: center;
}
.container-of-advantage .advantage-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-of-advantage .advantage-item p {
  text-align: center;
}
.advantages-section-container img {
  position: absolute;
  height: 100%;
  width: auto;
  z-index: 1;
  filter: brightness(94%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 1000px) {
  .container-of-advantage {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 600px) {
  .container-of-advantage {
    grid-template-columns: repeat(3, 1fr);
  }

  .container-of-advantage .advantage-item:last-of-type {
    grid-column: 3 / 4;
  }
}
