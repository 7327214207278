@import '../../scss/color';

.car-gallary-item {
  outline: 2px dashed $outline-color;
  position: relative;
}

.car-gallary-item__container {
  position: relative;
  padding: 0.5em;
}

.car-gallary-item__container img {
  --offset: 10px;
  max-width: 100%;
  min-height: 2em;
  height: auto;
  margin-bottom: 8em;
}

.car-gallary-item__container h1 {
  position: absolute;
  bottom: 1em;
  right: 0.5em;
}

.car-gallary-item__container h2 {
  position: absolute;
  bottom: 2.5em;
  right: 0.5em;
}

.car-gallary-item__actions {
  position: absolute;
  left: 0.5em;
  bottom: 0.5em;
}
