@import '../../../scss//color';

.transport-service-container {
  box-shadow: 0 0 0 100vmax $primary;
  clip-path: inset(0 -100vmax);
}
.transport-service-container__card {
  background-color: $primary;
  position: relative;
}

.transport-service-container__card__text {
  padding: 10%;
  position: absolute;
  bottom: 0;
  z-index: 1;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  text-align: start;
}
.transport-service-container .transport-service-container__card:nth-child(1) .transport-service-container__card__text {
  transition-delay: 0;
}
.transport-service-container .transport-service-container__card:nth-child(2) .transport-service-container__card__text {
  transition-delay: 150ms;
}
.transport-service-container .transport-service-container__card:nth-child(3) .transport-service-container__card__text {
  transition-delay: 300ms;
}

.transport-service-container__card__text h1 {
  color: #fff;
  font-weight: 800;
  font-size: 25px;
  border-bottom: #fff solid 1px;
}
.transport-service-container__card__text p {
  font-weight: 300;
  color: #fff;
  font-size: 16px;
}

.transport-service-container__card__img-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.transport-service-container__card__img-container img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  max-height: 450px;
  top: 0;
  left: 0;
  opacity: 0.7;
  -webkit-transition: all 4s ease;
  -moz-transition: all 4s ease;
  -o-transition: all 4s ease;
  -ms-transition: all 4s ease;
  transition: all 4s ease;
}

.transport-service-container .transport-service-container__card.hover {
  cursor: pointer;
  .transport-service-container__card__text {
    transform: translate(-50px, 0);
    -webkit-transform: translate(-50px, 0);
    -moz-transform: translate(-50px, 0);
    -o-transform: translate(-50px, 0);
    -ms-transform: translate(-50px, 0);
  }
  .transport-service-container__card__img-container img {
    opacity: 0.3;
    transform: scale(1.5);
  }
}
html[dir='ltr'] {
  .transport-service-container .transport-service-container__card.hover {
    .transport-service-container__card__text {
      transform: translate(50px, 0);
      -webkit-transform: translate(50px, 0);
      -moz-transform: translate(50px, 0);
      -o-transform: translate(50px, 0);
      -ms-transform: translate(50px, 0);
    }
  }
}
