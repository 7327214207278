@import './scss/color';

html,
body {
  font-family: 'Tajawal', sans-serif;
  background-color: $primary;
  padding-right: 0 !important;
  padding-left: 0 !important;
  overflow: auto !important;
}

//some hacks for bootstrap

.bg-white {
  background-color: white !important;
}
