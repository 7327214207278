@import '../../scss/color';

.cerrificated-container {
  height: 5em;
}

.cerrificated-container img {
  width: auto;
  height: 100%;
  cursor: pointer;
}

@media (max-width: 400px) {
  .cerrificated-container img {
    height: 80%;
  }
}
