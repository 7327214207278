.btn--contact-section {
  height: 20vmin !important;
}
.btn--contact-section {
  font-size: calc(2em + 1.2vmin);
}
.btn--contact-section {
  vertical-align: middle;
  padding-right: calc((2em + 1.2vmin) / 2) !important;
  padding-left: calc((2em + 1.2vmin) / 2) !important;
}
