@import '../../scss/color';

.hero-section {
  min-height: max(100vh, 375px);
  min-height: max(100dvh, 375px);
  position: relative;
  background-image: url('../../assets/imgs/kaaba-in-mecca1.jpg');
  background-position: top 70px center;
  background-size: cover;

  &::before {
    content: '';
    background: rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  &::after {
    content: '';
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3));
    z-index: 1;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
}
.hero-section__content {
  position: absolute;
  z-index: 2;
  left: 0dvw;
  bottom: 10vh;
  bottom: 10dvh;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-top: max(10vh, 6em);
  margin: 0 2em;
}

.hero-section__content h2 {
  max-width: 768px;
  color: white;
  text-align: center;
  margin-bottom: 1em;
}
