@import '../../../scss/color';

@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65, 0, 0.076, 1)) {
  transition: $property $duration $ease;
}

button.hero-btn {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  background-color: #fff;
  border-radius: 50vw;
  width: 16rem;
  height: auto;
}

.hero-btn__circle {
  @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
  position: relative;
  display: block;
  margin: 0 0 0 auto;
  width: 3rem;
  height: 3rem;
  background-color: $primary;
  border-radius: 1.625rem;
  border: 1px solid white;
}

.hero-btn__circle__icon {
  @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: white;
}

.hero-btn__circle__arrow {
  @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
  &::before {
    position: absolute;
    content: '';
    top: -0.25rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-bottom: 0.125rem solid #fff;
    border-left: 0.125rem solid #fff;
    transform: translateX(0) rotate(45deg);
  }
}
.hero-btn__caption {
  @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  color: $primary;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  vertical-align: middle;
}
@media (max-resolution: 1dppx) {
  button.hero-btn:hover {
    .hero-btn__circle {
      width: 100%;
    }

    .hero-btn__circle__arrow {
      background: white;
      transform: translate(1rem, 0);
      &::before {
        transform: translateX(-0.3125em) rotate(45deg);
      }
    }
  }
}
