@import '../../../scss/color';

.car-images-gallary-section__container {
  /* Prevent vertical gaps */
  --image-count-per-row: 1;
  --column-gap: 0;
  line-height: 0;
  -webkit-column-count: var(--image-count-per-row);
  -webkit-column-gap: var(--column-gap);
  -moz-column-count: var(--image-count-per-row);
  -moz-column-gap: var(--column-gap);
  column-count: var(--image-count-per-row);
  column-gap: var(--column-gap);

  & img {
    width: 100% !important;
    height: auto !important;
    border: 1px solid $outline-color;
    outline: 2px dashed $outline-color;
    outline-offset: -0.5em;
  }
}

@media (min-width: 576px) {
  .car-images-gallary-section__container {
    --image-count-per-row: 2;
  }
}

@media (min-width: 768px) {
  .car-images-gallary-section__container {
    --image-count-per-row: 3;
  }
}
