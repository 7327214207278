@import '../../scss/color';

.car-price-container {
  position: relative;
  // height: 100%;
}

.car-price-container img {
  width: 100%;
  height: auto;
}
